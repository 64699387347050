<template>
  <div class="home">
    <img src="https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/image/2024-2-1/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240201162505.jpg" style="width: 100%;height: 100%" alt="">
  </div>
</template>

<script>
export default {
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let CompanyInfo = localStorage.getItem("CompanyID")
      if (CompanyInfo) {
        this.$router.push('/login')
      }

      let query = this.$route.query
      let CompanyID = query.CompanyID
      // 接收作品ID WorkID 用于分享链接
      let WorkID = query.WorkID
      if (WorkID) {
        localStorage.setItem("WorkID", WorkID);
        localStorage.setItem("Shared", false);
      }
      if (CompanyID) {
        localStorage.setItem("CompanyID", CompanyID);
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home{
  width: 100%;
  height: 100%;
}
</style>
